import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-go-back-button',
    template: `
        <button
            dir="ltr"
            [routerLink]="['/', targetRoute || '']"
            [appTooltip]="'translate_back' | translate"
            class="btn btn-sm btn-white flex items-center gap-1"
        >
            <i class="fas fa-left"></i>

            <span class="hidden md:inline">
                {{ 'translate_back' | translate }}
            </span>
        </button>
    `,
})
export class GoBackButtonComponent {
    @Input() public targetRoute?: string;
}
