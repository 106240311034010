import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LibraryFile, Resource } from '@masar/common/models';
import { PermissionService } from '@masar/core/services';
import { permissionList } from '@masar/common/constants';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedLibraryFileService } from '@masar/shared/services';

@Component({
    selector: 'app-library-file-linked-resource-list',
    templateUrl: './library-file-linked-resource-list.component.html',
})
export class LibraryFileLinkedResourceListComponent
    implements OnInit, OnDestroy
{
    @Input() public item: LibraryFile;

    public linkedResources: Resource[];
    public resourcesLinks = new Map<string, string>();
    public resourcesLinksQueryParams = new Map<string, any>();

    private unsubscribeAll = new Subject();

    public constructor(
        private sharedLibraryFileService: SharedLibraryFileService,
        private permissionService: PermissionService
    ) {}

    public ngOnInit(): void {
        this.sharedLibraryFileService
            .getLinkedResources(this.item.id)
            .subscribe(items => {
                this.generateResourcesLinks();
                this.linkedResources = items;

                // This is strictly to monitor permission
                // changes.
                this.permissionService
                    .ensureUserHasPermission(permissionList.fullAccess)
                    .pipe(takeUntil(this.unsubscribeAll))
                    .subscribe(() => this.generateResourcesLinks());
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    private generateResourcesLinks(): void {
        if (!this.linkedResources) return;

        const getParam = (metadata: string[], param: string): string => {
            return metadata.find(x => x.startsWith(param))?.split('=')[1];
        };

        combineLatest([
            this.permissionService.ensureUserHasPermission(
                permissionList.kpiResultRead
            ),
            this.permissionService.ensureUserHasPermission(
                permissionList.capability
            ),
            this.permissionService.ensureUserHasPermission(
                permissionList.kpiRead
            ),
            this.permissionService.ensureUserHasPermission(
                permissionList.operation
            ),
            this.permissionService.ensureUserHasPermission(
                permissionList.planRead
            ),
            this.permissionService.ensureUserHasPermission(
                permissionList.benchmarkRead
            ),
            this.permissionService.ensureUserHasPermission(
                permissionList.tournamentRead
            ),
            this.permissionService.ensureUserHasPermission(
                permissionList.improvementOpportunity
            ),
        ])
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(
                ([
                    kpiResultPermission,
                    capabilityPermission,
                    kpiPermission,
                    operationPermission,
                    planPermission,
                    benchmarkPermission,
                    tournamentPermission,
                    improvementOpportunityPermission,
                ]) => {
                    this.linkedResources.forEach(item => {
                        const metadata = item.metadata.split('|');
                        let link: string;
                        let queryParams: any;

                        const kpiId = getParam(metadata, 'kpiId');
                        const operationId = getParam(metadata, 'operationId');

                        switch (item.type) {
                            case 'kpi_result_capability':
                                const departmentId = getParam(
                                    metadata,
                                    'departmentId'
                                );
                                const year = getParam(metadata, 'year');

                                link = kpiResultPermission
                                    ? `/kpi/update-result/${kpiId}`
                                    : null;
                                queryParams = { departmentId, year };

                                break;
                            case 'capability':
                                link = capabilityPermission
                                    ? `/capability/detail/${item.id}`
                                    : null;
                                break;
                            case 'kpi_benchmark':
                                link = kpiPermission
                                    ? `/kpi/detail/${kpiId}`
                                    : null;
                                break;
                            case 'operation':
                                link = operationPermission
                                    ? `/operation/detail/${item.id}`
                                    : null;
                                break;
                            case 'operation_enhancement':
                            case 'operation_procedure':
                                link = operationPermission
                                    ? `/operation/detail/${operationId}`
                                    : null;
                                break;
                            case 'plan_subsubtask':
                                const planSubtaskId = getParam(
                                    metadata,
                                    'planSubtaskId'
                                );
                                link = planPermission
                                    ? `/plan-subtask/detail/${planSubtaskId}`
                                    : null;
                                break;
                            case 'benchmark':
                                link = benchmarkPermission
                                    ? `/benchmark/detail/${item.id}`
                                    : null;
                                break;
                            case 'tournament':
                                link = tournamentPermission
                                    ? `/tournament/detail/${item.id}`
                                    : null;
                                break;
                            case 'standard':
                                link = tournamentPermission
                                    ? `/tournament/standard/detail/${item.id}`
                                    : null;
                                break;
                            case 'improvement_opportunity':
                                link = improvementOpportunityPermission
                                    ? `/opportunity/detail/${item.id}`
                                    : null;
                                break;
                            case 'standard_subtask':
                                link = tournamentPermission
                                    ? `/tournament/subtask/detail/${item.id}`
                                    : null;
                                break;
                            case 'operation_procedure_step':
                                link = operationPermission
                                    ? `/operation/detail/${operationId}`
                                    : null;
                                break;
                        }

                        this.resourcesLinks.set(item.id, link);
                        this.resourcesLinksQueryParams.set(
                            item.id,
                            queryParams
                        );
                    });
                }
            );
    }
}
