<div content class="flex flex-col gap-4">
    <app-alert
        *ngIf="!itemId"
        label="translate_evaluation_alert_info_title"
        description="translate_evaluation_alert_info_description"
        mode="info"
    ></app-alert>

    <div
        class="flex flex-col gap-1 rounded border bg-gray-100 p-4"
        *ngIf="!itemId && evaluations.length > 1"
    >
        <label>{{ 'translate_select_the_evaluation' | translate }}</label>
        <ng-select
            [items]="evaluations"
            [ngModel]="selectedEvaluation"
            (ngModelChange)="setSelectedEvaluation($event)"
            bindLabel="name"
            [clearable]="false"
            [searchable]="false"
        ></ng-select>
    </div>

    <!-- Records -->
    <app-list-loading [items]="item?.records">
        <div class="table-responsive !overflow-visible">
            <table>
                <thead>
                    <tr>
                        <th>{{ 'translate_standard' | translate }}</th>
                        <th class="w-80">
                            {{ 'translate_target' | translate }}
                        </th>
                        <th class="w-80">
                            {{ 'translate_value' | translate }}
                        </th>
                        <th class="w-80">
                            {{ 'translate_notes' | translate }}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let record of item?.records; let idx = index">
                        <!-- Name -->
                        <td>{{ record.name }}</td>

                        <!-- Target -->
                        <td class="text-center">{{ record.target }}</td>

                        <!-- Value -->
                        <td>
                            <ng-select
                                [items]="[
                                    0,
                                    record.target * 0.5,
                                    record.target
                                ]"
                                [clearable]="false"
                                [searchable]="false"
                                [(ngModel)]="item?.records[idx].value"
                                (ngModelChange)="checkForEmptyValues()"
                                [disabled]="mode === 'view'"
                            >
                                <ng-template ng-label-tmp let-item="item">
                                    <div
                                        class="absolute left-0 top-0 flex h-full w-full flex-row items-center px-4 text-white"
                                        [ngClass]="{
                                            'bg-red-500': item === 0,
                                            'bg-yellow-500':
                                                item === record.target * 0.5,
                                            'bg-green-500':
                                                item === record.target
                                        }"
                                    >
                                        {{ item }}
                                    </div>
                                </ng-template>

                                <ng-template
                                    ng-option-tmp
                                    let-option="item"
                                    let-index="index"
                                >
                                    <div
                                        class="-mx-[10px] -my-[8px] px-4 py-2 hover:text-white"
                                        [ngClass]="{
                                            'text-white':
                                                option ===
                                                item?.records[idx].value,
                                            'bg-red-500':
                                                option ===
                                                    item?.records[idx].value &&
                                                index === 0,
                                            'bg-yellow-500':
                                                option ===
                                                    item?.records[idx].value &&
                                                index === 1,
                                            'bg-green-500':
                                                option ===
                                                    item?.records[idx].value &&
                                                index === 2,
                                            'hover:bg-red-500': index === 0,
                                            'hover:bg-yellow-500': index === 1,
                                            'hover:bg-green-500': index === 2
                                        }"
                                    >
                                        {{ option }}
                                    </div>
                                </ng-template>
                            </ng-select>
                        </td>

                        <!-- Note -->
                        <td>
                            <textarea
                                class="w-full"
                                [disabled]="mode === 'view'"
                                [(ngModel)]="item?.records[idx].note"
                            ></textarea>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </app-list-loading>

    <!-- General notes -->
    <div class="flex flex-col gap-1">
        <label>{{ 'translate_general_notes' | translate }}</label>
        <textarea
            *appWaitUntilLoaded="item"
            [disabled]="mode === 'view'"
            [(ngModel)]="item.note"
        ></textarea>
    </div>

    <!-- Save button -->
    <div class="flex flex-row justify-center gap-4">
        <button
            *ngIf="mode !== 'view' && (selectedEvaluation || itemId)"
            (click)="processUpdate()"
            [disabled]="isSubmitting || hasEmptyValue"
            class="btn btn-primary flex flex-row items-center gap-2 self-center"
        >
            <app-loading-ring *ngIf="isSubmitting"></app-loading-ring>

            <i class="fa fa-save"></i>

            <span>{{ 'translate_save' | translate }}</span>
        </button>

        <ng-container
            [ngTemplateOutlet]="customAction?.template"
            [ngTemplateOutletContext]="{ item: customAction?.item }"
        ></ng-container>
    </div>
</div>
