<app-page pageTitle="{{ title | translate }}">
    <div content class="flex flex-col gap-5">
        <app-evaluation-statistics
            #statistics
            (evaluationChange)="evaluationChange.emit($event); reload()"
            type="{{ type }}"
        ></app-evaluation-statistics>

        <app-content
            *ngIf="hasListContent"
            contentTitle="{{ 'translate_evaluations' | translate }}"
        >
            <ng-container content>
                <ng-content select="[list]"></ng-content>
            </ng-container>
        </app-content>
    </div>
</app-page>

<ng-template #controlsTemplate let-item="item">
    <div class="flex flex-row items-center gap-2">
        <!-- Show evaluations -->
        <button
            (click)="onClickEvaluate(item)"
            *ngIf="item.evaluateActionAbility.canRead"
            class="btn btn-sm btn-warning"
        >
            {{ 'translate_evaluations_list' | translate }}
        </button>
    </div>
</ng-template>
