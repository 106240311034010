<nz-tree-select
    class="block w-full"
    [nzShowSearch]="true"
    [nzDropdownMatchSelectWidth]="true"
    [nzExpandedKeys]="expandKeys"
    [nzNodes]="nodes"
    [ngModel]="selectedNode"
    (ngModelChange)="selectedNode = $event; reportChange()"
    [nzDropdownStyle]="{ 'max-height': '300px' }"
    [nzAsyncData]="true"
    (nzExpandChange)="onExpandChange($event)"
    [nzTreeTemplate]="nzTreeTemplate"
    [nzDisplayWith]="displayNodeName"
    [nzMultiple]="isMultiple"
    [nzNotFoundContent]="customNoResultTemplate"
    [nzHideUnMatched]="true"
></nz-tree-select>

<ng-template #customNoResultTemplate>
    <div class="custom-no-result">
        <i nz-icon nzType="frown" nzTheme="outline"></i>
        <span>{{ 'translate_not_found' | translate }}</span>
    </div>
</ng-template>

<ng-template #nzTreeTemplate let-node>
    <span>{{ node.origin.name }}</span>
</ng-template>
