import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { EvaluationStatisticsComponent } from '@masar/features/evaluate/components';
import { EvaluationType } from '@masar/features/evaluate/types';
import { EvaluateService } from '@masar/features/evaluate/services/evaluate.service';
import { EvaluateItem } from '@masar/features/evaluate/interfaces';
import { Evaluation } from '@masar/common/models';

@Component({
    selector: 'app-evaluation-dashboard-page',
    templateUrl: './evaluation-dashboard-page.component.html',
})
export class EvaluationDashboardPageComponent {
    @Input() public title: string;

    @Input() public type: EvaluationType;

    @Input() public hasListContent = true;

    @Output() public update = new EventEmitter();

    @Output() public evaluationChange = new EventEmitter<Evaluation>();

    @ViewChild(EvaluationStatisticsComponent)
    private statistics: EvaluationStatisticsComponent;

    @ViewChild('controlsTemplate')
    private controlsTemplate: TemplateRef<any>;

    public constructor(public readonly evaluateService: EvaluateService) {}

    public getControlsTemplate(): TemplateRef<any> {
        return this.controlsTemplate;
    }

    public onClickEvaluate(item: EvaluateItem): void {
        this.evaluateService
            .showEvaluationsList(item, this.type, undefined, item.id)
            .then(obs =>
                obs.subscribe(() => {
                    this.reload();
                    this.statistics.reload();
                })
            );
    }

    public reload(): void {
        this.update.emit();
    }
}
