import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { animations } from './animations';
import {
    MiscApiService,
    SafeModeService,
    SidebarNotificationService,
    SignalrService,
} from '@masar/core/services';
import { messageTypeList } from '@masar/common/misc/message-type-list';
import { NavItem } from '@masar/shared/components/sidebar/types';
import { TranslateService } from '@ngx-translate/core';
import { uuid } from '@masar/common/utils';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: [animations],
})
export class SidebarComponent implements OnDestroy, OnInit, AfterViewInit {
    @Input() public isSidebarCollapsed: boolean;
    @Input() public navItems: NavItem[];
    @Input() public displayMode: 'dark' | 'light' = 'light';
    @Input() public enabledFilter = false;
    @Input() public scrollToItemOnNavigation = true;

    public uuid: string = `sidebar-${uuid()}`;

    public filteredItems: NavItem[];

    // Used as bases for the item's children, since
    // during filtering the children property will
    // be overwritten with the filtered children.
    // When we perform the search again we need to filter
    // items' children based on the original children list.
    // However, the children list will have been overwritten
    // by previous filtering.
    private itemToChildren: Map<NavItem, NavItem[]>;

    private unsubscribeAll = new Subject();

    public constructor(
        public miscApiService: MiscApiService,
        public safeModeService: SafeModeService,
        private readonly sidebarNotificationService: SidebarNotificationService,
        private readonly signalrService: SignalrService,
        private readonly router: Router,
        private readonly translateService: TranslateService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly elementRef: ElementRef
    ) {}

    public ngOnInit(): void {
        this.hashItemsChildren();
        this.translateTitles();
        this.filterItems('');
        this.refreshMyTasksCount();

        // Because we are listening inside the ngOnInit, the first router
        // event won't be fired. We need to check before listening to account
        // for the first refresh.
        setTimeout(() => this.updateActiveStatus());

        this.router.events
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(event => {
                if (!(event instanceof NavigationEnd)) return;

                this.updateActiveStatus();
            });

        this.signalrService
            .messages()
            .pipe(
                takeUntil(this.unsubscribeAll),
                filter(
                    x =>
                        x.type ===
                        messageTypeList.myTasksNotificationCountUpdate
                )
            )
            .subscribe(() => this.refreshMyTasksCount());
    }

    public ngAfterViewInit(): void {
        this.scrollIntoActiveLink();
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    public toggleExpandableItem(item: NavItem): void {
        // Look for all items that should
        // not be closed by the toggle, basically
        // all of the upper level parents.
        const parents = [];
        const traverser = (items: NavItem[]): boolean => {
            const foundItem = items.find(x => {
                const children = this.itemToChildren.get(x);
                return (
                    x === item ||
                    ((x.type === 'expandable' || x.type == 'section') &&
                        children &&
                        children.length > 0 &&
                        traverser(children))
                );
            });

            if (!foundItem) return false;

            parents.push(foundItem);
            return true;
        };
        traverser(this.navItems);

        // Close all items that are not in the path.
        const closer = (items: NavItem[]): void => {
            items.forEach(x => {
                if (!parents.includes(x)) {
                    x.isOpen = false;
                }

                const children = this.itemToChildren.get(x);

                if (
                    (x.type === 'expandable' || x.type === 'section') &&
                    children &&
                    children.length > 0
                ) {
                    closer(children);
                }
            });
        };
        closer(this.navItems);

        // Toggle the item.
        item.isOpen = !item.isOpen;
    }

    public filterItems(keyword: string): void {
        const deepFilter = (items: NavItem[]): NavItem[] => {
            return items.filter(item => {
                switch (item.type) {
                    case 'item':
                        return item.title.includes(keyword);
                    case 'expandable':
                    case 'section':
                        const children = this.itemToChildren.get(item);
                        if (!children || children.length === 0) return false;
                        item.children = deepFilter(children);
                        return item.children.length > 0;
                }
            });
        };

        this.filteredItems = deepFilter(this.navItems);
    }

    private refreshMyTasksCount(): void {
        this.sidebarNotificationService.getMyTasksCount().subscribe(count => {
            const section = this.navItems.find(
                x => x.tag === 'excellence_management'
            );

            if (!section) return;

            const expandable = this.itemToChildren
                .get(section)
                .find(x => x.tag === 'excellence_tournaments');

            if (!expandable) return;

            expandable.badgeCount = count;

            const myTasksItem = this.itemToChildren
                .get(expandable)
                .find(x => x.tag === 'my_tasks');

            if (!myTasksItem) return;

            myTasksItem.badgeCount = count;
        });
    }

    private scrollIntoActiveLink(): void {
        if (!this.scrollToItemOnNavigation) return;

        const activeElement = this.elementRef.nativeElement.querySelector(
            `#${this.uuid} .nav-item-link.active`
        );

        activeElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
        });
    }

    private translateTitles(): void {
        const deepTranslate = (items: NavItem[]): void => {
            items.forEach(item => {
                item.title = this.translateService.instant(item.title);

                const children = this.itemToChildren.get(item);

                if (children && children.length > 0) {
                    deepTranslate(children);
                }
            });
        };

        deepTranslate(this.navItems);
    }

    private updateActiveStatus(): void {
        const resetActiveStates = (items: NavItem[]): void => {
            items.forEach(x => {
                x.isActive = false;
                x.isOpen = false;
                const children = this.itemToChildren.get(x);
                if (children) resetActiveStates(children);
            });
        };
        const check = (items: NavItem[]): boolean => {
            const currentPath = this.router.url.split('?')[0];
            const segments =
                currentPath === '/' ? [''] : currentPath.split('/');

            return items.some(x => {
                if (x.type === 'item') {
                    // Create path from link array and compare with current path segments
                    const linkPath = x.link
                        ? '/' + x.link.filter(Boolean).join('/')
                        : '';
                    x.isActive = linkPath === currentPath;
                    return x.isActive;
                }
                const children = this.itemToChildren.get(x);
                const hasActiveChild = check(children);
                x.isOpen =
                    hasActiveChild ||
                    (segments.length > 1 &&
                        children?.[0]?.link?.[1] === segments[1]);
                return x.isOpen;
            });
        };
        resetActiveStates(this.navItems);
        check(this.navItems);
        this.changeDetectorRef.detectChanges();
        this.scrollIntoActiveLink();
    }

    private hashItemsChildren(): void {
        this.itemToChildren = new Map<NavItem, NavItem[]>();
        const traverser = (items: NavItem[]): void => {
            items.forEach(item => {
                if (!item.children || item.children.length === 0) return;
                this.itemToChildren.set(item, item.children);
                traverser(item.children);
            });
        };
        traverser(this.navItems);
    }
}
