import { Component, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Permission } from '@masar/common/models';
import { MiscApiService } from '@masar/core/services';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { functions } from '@masar/common/misc/functions';
import { isOrigin } from '@masar/common/utils';
import { OrganizationOrigin } from '@masar/common/enums';

interface PermissionCategory {
    id: string;
    permissions: PermissionWithCheckboxStatus[];
}

interface PermissionWithCheckboxStatus {
    permission: Permission;
    checked: boolean;
    disabled: boolean;
}

@Component({
    selector: 'app-permission-field',
    templateUrl: './permission-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => PermissionFieldComponent),
        },
    ],
})
export class PermissionFieldComponent
    implements ControlValueAccessor, OnDestroy
{
    public isLoading = true;
    public items$ = new BehaviorSubject<PermissionCategory[]>(null);
    // public selectedItems: Permission[] = [];

    private overridenMap = new Map<string, PermissionWithCheckboxStatus[]>();
    private onChange: (data: string[]) => void;

    public constructor(miscApiService: MiscApiService) {
        miscApiService.permissions().subscribe(x => {
            const items = x
                .filter(
                    x =>
                        !isOrigin([
                            OrganizationOrigin.injaz,
                            OrganizationOrigin.police,
                        ]) || !x.id.includes('risk')
                )
                .map(permission => ({
                    checked: false,
                    disabled: false,
                    permission,
                }));

            items.forEach(x => {
                this.overridenMap.set(
                    x.permission.id,
                    this.getAllOverridenIdList(x.permission.id, items)
                );
            });

            const categorizedItems = items.map(x => ({
                categoryId: x.permission.id.split(':')[0],
                permission: x,
            }));
            this.items$.next(
                functions
                    .groupBy(categorizedItems, x => x.categoryId)
                    .map(x => ({
                        id: x.key,
                        permissions: x.items.map(y => y.permission),
                    }))
            );

            this.isLoading = false;
        });
    }

    public ngOnDestroy(): void {
        this.items$.complete();
    }

    public writeValue(data: string[]): void {
        data?.forEach(itemId => {
            this.items$
                .pipe(filter(x => x !== null))
                .pipe(first())
                .subscribe(items => {
                    const item = items
                        .flatMap(x => x.permissions)
                        .find(x => x.permission.id === itemId);
                    this.onToggle(item, true, false);
                });
        });
    }

    public registerOnChange(onChange: (data: string[]) => void): void {
        this.onChange = onChange;
    }

    public registerOnTouched(_: any): void {
        return undefined;
    }

    public onToggle(
        item: PermissionWithCheckboxStatus,
        checked: boolean,
        reportChange: boolean = true
    ): void {
        item.checked = checked;

        const overridenPermissions = this.overridenMap.get(item.permission.id);
        // Look for all permissions that
        // can be over-ridden by the selected
        // permission.
        if (checked) {
            overridenPermissions.forEach(x => {
                x.checked = false;
                x.disabled = true;
            });
        } else {
            this.items$.pipe(first()).subscribe(items => {
                overridenPermissions.forEach(x => {
                    // Ensure that there aren't any overriders
                    // to this permission that is still checked.
                    x.disabled = items
                        .flatMap(y => y.permissions)
                        .some(
                            y =>
                                x.permission.overriders.includes(
                                    y.permission.id
                                ) && y.checked
                        );
                });
            });
        }

        // Report change to form (if any)
        if (this.onChange && reportChange) {
            this.items$
                .pipe(filter(x => x !== null))
                .pipe(first())
                .subscribe(items => {
                    this.onChange(
                        items
                            .flatMap(x => x.permissions)
                            .filter(x => x.checked)
                            .map(x => x.permission.id)
                    );
                });
        }
    }

    private getAllOverridenIdList(
        permissionId: string,
        items: PermissionWithCheckboxStatus[]
    ): PermissionWithCheckboxStatus[] {
        return items.filter(x =>
            x.permission.overriders.includes(permissionId)
        );
    }
}
